let fieldsets = document.querySelectorAll('[data-fieldset-item]');

fieldsets.forEach((elm, index)=> {
  let allInputs = elm.querySelectorAll('input, select');
  let populatedInputCount = 0;
  let showNextButton = elm.querySelector('[data-show-next-fieldset]');

  allInputs.forEach((e)=> {
    if (e.value) {
      ++populatedInputCount;
    }
  });

  // on load, hide empty sets after the first one.
  if (populatedInputCount === 0 && index > 1) {
    elm.classList.add('hide');
  }

  // on load, show first 'add' button if empty
  if (populatedInputCount === 0 && index === 1) {
    showNextButton.classList.remove('hide');
  }

  function showNext(e){
    e.preventDefault();
    let nextFieldSetItem = elm.nextElementSibling;
    let nextSetButton = nextFieldSetItem.querySelector('[data-show-next-fieldset]')
    showNextButton.classList.add('hide');
    nextFieldSetItem.classList.remove('hide');
    if (nextSetButton) {
      nextSetButton.classList.remove('hide');
    }

  };

  if (showNextButton){
    showNextButton.addEventListener("click", showNext);
  }
});


// disable submit button until all required fields are populated
let forms = document.querySelectorAll('form[data-submit-disable]');
function checkValidity(){
  forms.forEach((elm)=> {
    let submitBtn = elm.querySelector('[type="submit"]');
    let invalidFields = elm.querySelectorAll('input:invalid').length > 0;
    if (invalidFields) {
      submitBtn.setAttribute('disabled', '');
    } else {
      submitBtn.removeAttribute('disabled');
    }
  });
}

checkValidity();

let invalidFields = document.querySelectorAll('form[data-submit-disable] input:invalid');
invalidFields.forEach((e)=> {
  e.addEventListener('input', checkValidity);
});
