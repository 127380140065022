const removePlayerButtons = document.querySelectorAll('[data-remove-id]');

removePlayerButtons.forEach(function(e) {
  let thisPlayerID = e.getAttribute('data-remove-id');
  let thisPlayerName = e.getAttribute('data-remove-name');
  let thisRow = e.parentElement.parentElement;

  function openConfirm() {
    if (window.confirm("Are you sure you want to remove " + thisPlayerName + " from this game?" ) ) {
      thisRow.classList.add('hide');
      console.log('TODO: actually delete this user');
    }
  }

  e.addEventListener('click', openConfirm);
});
