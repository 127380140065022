let optionsField = document.querySelectorAll(".option-form");
let optionsFormContainer = document.querySelector("[data-append-options-here]");
let addOptionButton = document.querySelector("#add-option");
let totalOptions = document.querySelector("#id_form-TOTAL_FORMS");

let optionNum = optionsField.length-1;

let addOptionForm = function(e){
    e.preventDefault();
    let newForm = optionsField[0].cloneNode(true);
    let formRegex = RegExp(`form-(\\d){1}-`,'g'); // Regex to find all instances of form number

    optionNum++
    newForm.innerHTML = newForm.innerHTML.replace(formRegex, `form-${optionNum}-`);

    optionsFormContainer.append(newForm, addOptionButton);
    totalOptions.setAttribute('value', `${optionNum+1}`);
};

if (addOptionButton) {
  addOptionButton.addEventListener('click', addOptionForm);  
}
