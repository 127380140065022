import Choices from 'choices.js';

const selects = document.querySelectorAll(".choices-js select");
const dataElem = document.getElementById('question-options');
const questionOptions = JSON.parse(dataElem != null ? dataElem.textContent : "{}");
Array(...selects).forEach((e) =>
  document.addEventListener('DOMContentLoaded', function() {
    new Choices(e, {
      searchEnabled: false,
      callbackOnCreateTemplates: function(strToEl) {
        var itemSelectText = this.config.itemSelectText;
        return {
          item: function(classNames, data) {
            const question = questionOptions[data.label] || {};
            const options = question.options || [];
            return strToEl(
              '\
              <div\
                class="' +
              String(classNames.item) +
              ' ' +
              String(
                data.highlighted
                  ? classNames.highlightedState
                  : classNames.itemSelectable
              ) +
              '"\
              data-item\
              data-id="' +
              String(data.id) +
              '"\
              data-value="' +
              String(data.value) +
              '"\
              ' +
              String(data.active ? 'aria-selected="true"' : '') +
              '\
              ' +
              String(data.disabled ? 'aria-disabled="true"' : '') +
              '\>' +
              String(data.label) +
              '\<span class=options\>' +
              String(options.join(', ')) +
              '\</span\>' +
              '\</div\>'
            );
          },
          choice: function(classNames, data) {
            const question = questionOptions[data.label] || {};
            const options = question.options || [];

            return strToEl(
              '\
              <div\
                class="' +
              String(classNames.item) +
              ' ' +
              String(classNames.itemChoice) +
              ' ' +
              String(
                data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              ) +
              '"\
              data-select-text="' +
              String(itemSelectText) +
              '"\
              data-choice \
              ' +
              String(
                data.disabled
                  ? 'data-choice-disabled aria-disabled="true"'
                  : 'data-choice-selectable'
              ) +
              '\
              data-id="' +
              String(data.id) +
              '"\
              data-value="' +
              String(data.value) +
              '"\
              ' +
              String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"') +
              '>' +
              String(data.label) +
              '\<span class=options\>' +
              String(options.join(', ')) +
              '\</span\>' +
              '\</div\>'
            );
          },
        };
      },
    });
  }));
